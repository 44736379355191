<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-between mb-4 table-label-search">
          <h3>Companies</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="search" placeholder="Keyword Search" @input="onSearch" />
          </span>
        </div>
        <AddCredits/>
        <EditCompany/>

        <Dialog v-model:visible="dialog" :style="{width: '450px'}" header="Rejection reason" :modal="true" class="p-fluid" @hide="closeDialog">
          <!-- Message -->
          <div class="field">
            <div class="p-float-label">
              <Textarea
                id="message" rows="5" cols="30"
                v-model="v$.form.message.$model"
                :class="{'p-invalid':v$.form.message.$invalid && submitted}"
              />

              <label for="message" :class="{'p-error':v$.form.message.$invalid && submitted}">
                Message
              </label>
            </div>

            <small
              v-if="(v$.form.message.$invalid && submitted) || v$.form.message.$pending.$response"
              class="p-error">{{ v$.form.message.required.$message.replace('Value', 'Message') }}
            </small>
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="handleSubmit(!v$.form.$invalid)" />
          </template>
        </Dialog>

        <DataTable
          :value="getCompanies" :lazy="true" :paginator="true" :first="getPagination?.from-1"
          :rows="getPagination?.per_page ?? 25"  ref="dt" dataKey="id"
          :totalRecords="getPagination.total" :loading="loading" @page="onPage($event)"
          @sort="onSort($event)" :sortField="sort" :sortOrder="order"
          responsiveLayout="scroll" :rowsPerPageOptions="[10,25,50]"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <template #empty>
            Not found.
          </template>

          <!-- Company Name -->
          <Column field="name" header="Company Name" :sortable="true"/>

          <!-- Admin Full Name -->
          <Column field="admin_name" header="Admin Full Name" :sortable="true">
            <template #body="slotProps">
              {{slotProps.data.first_name + ' ' + slotProps.data.last_name}}
            </template>
          </Column>

          <!-- Admin Email -->
          <Column field="email" header="Admin Email" :sortable="true" />

          <!-- Balance -->
          <Column field="balance" header="Balance" :sortable="true" />

          <!-- Frozen Balance -->
          <Column field="frozen_balance" header="Frozen Balance" :sortable="true" />

          <!-- Notes -->
          <Column field="notes" header="Notes" :sortable="true" />

          <!-- Actions -->
          <Column headerStyle="min-width:16rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-wallet" class="p-button-rounded p-button-success mr-2"
                v-tooltip="'Add credits'"
                @click="addBalance(slotProps.data.id)"
              />
              <Button
                icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2"
                v-tooltip="'Edit company'"
                @click="edit(slotProps.data.id)"
              />
              <Button
                icon="pi pi-eye" class="p-button-rounded p-button-info mr-2"
                v-tooltip="'View company'"
                @click="$router.push({name: 'company', params: {id: slotProps.data.id}})"
              />
              <Button
                v-tooltip="'Block'"
                icon="pi pi-times" class="p-button-rounded p-button-danger mr-2"
                @click="reject(slotProps.data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import EditCompany from "@pages/app/Companies/EditCompany";
import AddCredits from "@pages/app/Companies/AddCredits";
import store from "@store";
import dataTable from "@/mixins/dataTable";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import companyResource from "@http/CompanyResource";
import toastParamBuilder from "@builders/ToastParamBuilder";

export default {
  setup: () => ({v$: useVuelidate()}),

  mixins: [dataTable],

  components: { EditCompany, AddCredits },

  data() {
    return {
      dialog: false,
      id: null,
      form: {
        message: null,
      },
      submitted: false,
    }
  },

  validations() {
    return {
      form: {
        message: {required},
      }
    }
  },

  methods: {
    ...mapActions({
      countActiveRequests: 'company/countActiveRequests',
    }),
    addBalance(id) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          companyBalance: id,
        }
      });
    },
    edit(id) {
      this.loading = true;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          company: id,
        }
      });
    },
    reject(id) {
      this.id = id;
      this.dialog = true;
    },
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      companyResource.reject(this.id, this.form)
        .then(({data}) => {
          this.$toast.add(toastParamBuilder.success(data));
          this.countActiveRequests();
          this.closeDialog();
          this.resetForm();
        })
        .catch(error => {
          this.$toast.add(toastParamBuilder.error(error?.response?.data?.message ? error?.response?.data?.message : error.message));
        });
    },

    closeDialog() {
      this.dialog = false;
    },

    resetForm() {
      this.id = null;
      this.form.message = null;
      this.submitted = false;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    }
  },

  computed: {
    ...mapGetters({
      getCompanies: "company/getCompanies",
      getPagination: "company/getPagination"
    }),
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch('company/companies', to.query)
      .finally(() => {
        next((vm) => {
          vm.loading = false;
        });
      });
  },

  async beforeRouteUpdate(to) {
    await store.dispatch('company/companies', to.query)
      .finally(() => {
        this.loading = false;
      });
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
::v-deep(.p-paginator) {
 .p-paginator-current {
   margin-left: auto;
 }
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
</style>
