<template>
  <div>
    <Dialog @hide="closeDialog" v-model:visible="dialog" :style="{width: '450px'}" header="Add credits to company balance" :modal="true" class="p-fluid">
      <div class="field">
        <div class="p-float-label mb-5">
          <InputText id="balance" v-model="v$.form.balance.$model" :class="{'p-invalid':v$.form.balance.$invalid && submitted}"/>
          <label for="balance" :class="{'p-error':v$.form.balance.$invalid && submitted}">Balance*</label>
        </div>
        <div class="mb-5">
          <SelectButton v-model="type" :options="typeOptions" aria-labelledby="basic" option-label="name" option-value="value" allowEmpty="false" />
        </div>
        <small
            v-if="(v$.form.balance.$invalid && submitted) || v$.form.balance.$pending.$response"
            class="p-error">{{ v$.form.balance.required.$message.replace('Value', 'Balance') }}
        </small>
      </div>

      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click.prevent="dialog = false" />
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click.once="saveBalance(!v$.$invalid)" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import companyResource from "@http/CompanyResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import {mapActions} from 'vuex';

export default {
  setup: () => ({v$: useVuelidate()}),
  emits: ["hideDialog"],
  data() {
    return {
      dialog: false,
      submitted: false,
      form: {
        balance: null,
      },
      type: 'increment',
      typeOptions: [{name: 'Increment', value: 'increment'}, {name: 'Decrement', value: 'decrement'}]
    }
  },
  mounted() {
    let query = Object.assign({}, this.$route.query);
    if(query.companyBalance) {
      this.dialog = true;
    }
  },
  watch: {
    '$route.query': {
      handler: function (query) {
        if (query.companyBalance) {
          this.dialog = true;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      company: 'company/company',
    }),
    closeDialog() {
      this.submitted = false;
      this.dialog = false;
      this.form = {};
      let query = Object.assign({}, this.$route.query);
      delete query.companyBalance;
      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    saveBalance(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }
      companyResource.updateBalance(this.$route.query?.companyBalance, this.form.balance, this.type)
          .then(({data}) => {
            this.$toast.add(toastParamBuilder.success(data));
            this.company();
            this.loading = true;
            this.closeDialog();
          })
          .catch(error => {
            this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
          });
    }
  },
  validations() {
    return {
      form: {
        balance: { numeric, required },
      },
    };
  },
}
</script>

<style scoped>

</style>
